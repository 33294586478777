import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';

@withGlobalProps
export class DetailsPlaceholder extends React.Component<ProvidedGlobalProps> {
  public render(): JSX.Element {
    const {globals} = this.props;
    return globals.experiments.isEmbeddedScriptPlaceHolderEnabled && <div data-hook="details-placeholder" />;
  }
}
