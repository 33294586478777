import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {ProductPrice} from './ProductPrice/ProductPrice';
import {get, pick} from 'lodash';

export interface ProductPriceContainerProps extends ProvidedGlobalProps {
  className?: string;
}

const priceKeys = ['price', 'formattedPrice', 'comparePrice', 'formattedComparePrice'];

@withGlobalProps
export class ProductPriceContainer extends React.Component<ProductPriceContainerProps> {
  public render(): JSX.Element {
    let prices;
    const {
      className,
      globals: {product, selectedVariant},
    } = this.props;

    prices = pick(selectedVariant || product, priceKeys);

    if (prices.comparePrice) {
      prices = {
        price: prices.comparePrice,
        formattedPrice: prices.formattedComparePrice,
        comparePrice: prices.price,
        formattedComparePrice: prices.formattedPrice,
      };
    }

    const selectedPlan = get(this.props.globals.userInputs.subscriptionPlan, ['0']);

    return <ProductPrice {...prices} plan={selectedPlan} className={className} />;
  }
}
