import React from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';

@withGlobalProps
export class BottomPlaceholder extends React.Component<ProvidedGlobalProps> {
  public render(): JSX.Element {
    const {globals} = this.props;
    return globals.experiments.isEmbeddedScriptPlaceHolderEnabled && <div data-hook="bottom-placeholder" />;
  }
}
