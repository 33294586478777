import React from 'react';
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import {withGlobalPropsProvider} from '../providers/globalPropsProvider';
import {getRuntimeStyleParams, getRuntimeStyleOverrides} from './styleParamsService';
import {withDefaultStyleParams} from '@wix/wixstores-client-common-components/dist/src/outOfIframes/defaultStyleParams/withDefaultStyleParams';
import {withStrictMode} from '@wix/wixstores-client-common-components/dist/es/src/HOC/strictMode';

export const bootstrapApp = (Component: React.ComponentType, {cssPath}: {cssPath?: string[]} = {}) => {
  return withDefaultStyleParams(
    withStyles(withStrictMode(withGlobalPropsProvider(Component)), cssPath ? {cssPath} : {}),
    ({
      host: {
        style: {styleParams},
      },
      isMobile,
      isResponsive,
    }) => {
      return {
        defaults: getRuntimeStyleParams(styleParams),
        overrides: getRuntimeStyleOverrides({isMobile, isResponsive}),
      };
    }
  );
};
