import React from 'react';
import {Navigation} from '../../Navigation/Navigation';
import {ProductDescription} from '../../ProductDescription/ProductDescription';
import {InfoSectionLayout} from '../../InfoSectionLayout/InfoSectionLayout';
import {ProductOptions} from '../../ProductOptions/ProductOptions';
import {ProductSocial} from '../../ProductSocial/ProductSocial';
import {ProductName} from '../../ProductName/ProductName';
import {ProductSku} from '../../ProductSku/ProductSku';

import s from './SleekLayout.scss';
import {Cell} from '../Cell/Cell';
import {ProductGalleryLayout} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayout';
import {LayoutComponentProps} from '../../../types/app-types';
import {ProductPriceContainer} from '../../ProductPriceContainer/ProductPriceContainer';
import {convertCssValueToConfig} from '../../../commons/utils';
import {MarginBottom} from '../../../constants';
import {createLayoutConfigWithDefaults} from '../../ProductGallery/ProductGalleryLayout/ProductGalleryLayoutUtils';
import {BottomPlaceholder} from '../../Placeholders/BottomPlaceholder';
import {DetailsPlaceholder} from '../../Placeholders/DetailsPlaceholder';

export const SleekLayout: React.FunctionComponent<LayoutComponentProps> = ({product, settings}) => (
  <article className={s.container}>
    <Cell className={s.main}>{settings.shouldShowNavigation && <Navigation className={s.navigation} />}</Cell>
    <div className={s.headerContainer}>
      <header className={s.header}>
        <ProductGalleryLayout
          product={product}
          layoutConfig={createLayoutConfigWithDefaults({
            marginBottom: MarginBottom.LARGE,
            dimensions: {
              mainMedia: {
                widthConf: convertCssValueToConfig(s.sharedStyleVariables_galleryWidth),
                heightConf: convertCssValueToConfig(s.sharedStyleVariables_galleryHeight),
              },
              thumbnails: {
                widthConf: convertCssValueToConfig(s.sharedStyleVariables_galleryWidth),
                heightConf: {num: 50, unit: 'px'},
              },
            },
          })}
          {...settings}
        />
      </header>
    </div>
    <main className={s.main}>
      <section className={s.section}>
        <Cell className={s.title}>
          <ProductName name={product.name} />
        </Cell>
        {settings.shouldShowPrice && (
          <Cell className={s.price}>
            <ProductPriceContainer />
          </Cell>
        )}
        <Cell>
          <DetailsPlaceholder />
        </Cell>
        <div>
          <Cell className={s.description}>
            <ProductDescription description={product.description} />
          </Cell>
        </div>
        <div>
          <Cell className={s.options}>
            <ProductOptions
              shouldShowAddToCartButton={settings.shouldShowAddToCartButton}
              shouldShowQuantity={settings.shouldShowQuantity}
            />
          </Cell>
        </div>
        {settings.shouldShowSku && (
          <Cell className={s.sku}>
            <ProductSku />
          </Cell>
        )}
        {settings.shouldShowSocialNetwork && (
          <Cell className={s.social}>
            <ProductSocial />
          </Cell>
        )}
      </section>
    </main>
    <div className={s.footerContainer}>
      {settings.shouldShowInfoSection && (
        <footer className={s.footer}>
          <Cell className={s.info}>
            <InfoSectionLayout />
          </Cell>
        </footer>
      )}
    </div>
    <Cell>
      <BottomPlaceholder />
    </Cell>
  </article>
);
